<ex-dropdown
  class="no-select"
  icon="/assets/images/27-icon-calendar.svg"
  replaceChevron
  placeholder="Sélectionnez une date"
  noInput
  [inputText]="inputText"
  (change)="exDropdownChangeMethodTriggered($event)"
  (dropdownToggle)="dropdownToggled($event)"
  size="small"
  shadowBorder
>

  @if (displayedEstimatedEndPeriod) {
    <ng-template exDropdownDisplayLeftPart>
      <div class="date-filter-mode-selector d-flex">
        <button class="btn-selection"
                [ngClass]="{'selected': filteringPeriodType === FilteringPeriodTypeEnum.INDEXATION}"
                (click)="setDatePeriodMode(FilteringPeriodTypeEnum.INDEXATION, $event)">
          {{ 'tenders.filter.' + FilteringPeriodTypeEnum.INDEXATION | translate }}
        </button>
        <button class="btn-selection" disabled
                longHoverOnElement (longHoverTriggered)="managePastille()"
                [ngbTooltip]="soonTooltip"
                placement="bottom left"
                tooltipClass="custom-tooltip-soon"
                [ngClass]="{'selected': filteringPeriodType === FilteringPeriodTypeEnum.ESTIMATED_END,
                            'pastille': showPastille}"
                (click)="setDatePeriodMode(FilteringPeriodTypeEnum.ESTIMATED_END, $event)">
          {{ 'tenders.filter.' + FilteringPeriodTypeEnum.ESTIMATED_END | translate }}
        </button>
      </div>
    </ng-template>
  }

  <ng-template exDropdownContent class="tender-filter-date">
    <ngb-datepicker [hidden]="tenderSearchService._filters$.value?.estimated_end_period"
                    (dateSelect)="onDateSelection($event)"
                    [displayMonths]="2"
                    [maxDate]="maxDate"
                    [dayTemplate]="dayTemplate"
                    [startDate]="startDate"
                    outsideDays="hidden">
    </ngb-datepicker>
    <ngb-datepicker [hidden]="tenderSearchService._filters$.value?.indexation_period"
                    (dateSelect)="onDateSelection($event)"
                    [displayMonths]="2"
                    [maxDate]="{year: 2030, month: 12, day: 31}"
                    [minDate]="{year: 2019, month: 1, day: 1}"
                    [dayTemplate]="dayTemplate"
                    [startDate]="startDate"
                    outsideDays="hidden">
    </ngb-datepicker>
  </ng-template>

</ex-dropdown>


<ng-template #dayTemplate let-date let-focused="focused" let-disabled="disabled">
	<span
    class="custom-day"
    [class.focused]="focused"
    [class.disabled]="disabled"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
		{{ date.day }}
	</span>
</ng-template>

<ng-template #soonTooltip>
  <div class="ph-text-align-left">
    <span class="fw-600">Bientôt disponible</span>
  </div>
  <div class="ph-text-align-left">
    <span>Détectez vos prochains marchés en filtrant par date de fin de marché</span>
  </div>
</ng-template>
